import React from 'react'
import { Container, Grid } from '@mui/material'
import Instance from '../../instance'
import { useInstances } from '../../../api/instances'
import Title from '../../title'
import { TEAL_1 } from '../../../constants/brand'
import FakeInstance from './fake_instance'

const Instances = () => {
  const { instances, loading } = useInstances()

  if (loading) {
    return (
      <Container>
        <Title title='Instances' colors={[TEAL_1, TEAL_1]} />

        <Grid container direction='row' spacing={3} alignItems='stretch'>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19].map((number) => (
            <Grid key={number} item md={6} sm={12}>
              <FakeInstance />
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }

  return (
    <Container>
      <Title title='Testing Instances' colors={[TEAL_1, TEAL_1]} />

      <Grid container direction='row' spacing={3} alignItems='stretch'>
        {instances.map((instance) => (
          <Grid key={instance.name} item md={6} sm={12} xs={12}>
            <Instance instance={instance} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Instances
