import {
  CTA_GREEN,
  CTA_PURPLE,
  CTA_SALMON,
  GRAY_4,
  GREEN_GROWN_UP_GREEN,
  TEAL_1,
  TEXT_DARK,
  WARNING,
  WARNING_DARK,
  WHITE
} from './constants/brand'

export const GENERAL_BORDER_RADIUS = 16
export const INPUT_BORDER_RADIUS = 8

const override = (components, styles) =>
  components.reduce((acc, componentName) => {
    acc[componentName] = styles
    return acc
  }, {})

export default {
  typography: {
    fontSize: 14,
    fontFamily: [
      'Nunito Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: '40px',
      lineHeight: '48px'
    },
    h2: {
      fontSize: '34px',
      lineHeight: '40px'
    },
    h3: {
      fontSize: '28px',
      lineHeight: '32px'
    },
    h4: {
      fontSize: '20px',
      lineHeight: '24px'
    },
    h5: {
      fontSize: '18px',
      lineHeight: '22px'
    },
    h6: {
      fontSize: '16px',
      lineHeight: '20px'
    }
  },
  palette: {
    primary: {
      main: GREEN_GROWN_UP_GREEN,
      contrastText: WHITE
    },
    secondary: {
      main: CTA_PURPLE,
      contrastText: WHITE
    },
    error: {
      main: CTA_SALMON,
      contrastText: WHITE
    },
    warning: {
      dark: WARNING_DARK,
      main: WARNING,
      contrastText: WHITE
    },
    info: {
      main: TEAL_1,
      contrastText: WHITE
    },
    success: {
      main: CTA_GREEN,
      contrastText: WHITE
    },
    background: {
      default: GRAY_4
    },
    text: {
      default: TEXT_DARK
    },
    contrastThreshold: 2.4
  },
  shape: {
    borderRadius: GENERAL_BORDER_RADIUS
  },
  shadows: [
    'none',
    '0px 4px 4px 0px rgba(102, 101, 101, 0.08)',
    '0px 6px 6px 0px rgba(92, 91, 91, 0.16)',
    '0px 8px 8px 0px rgba(82, 81, 81, 0.24)',
    '0px 10px 10px 0px rgba(72, 71, 71, 0.32)',
    '0px 12px 12px 0px rgba(62, 61, 61, 0.40)',
    '0px 14px 14px 0px rgba(62, 61, 61, 0.40)',
    '0px 16px 16px 0px rgba(62, 61, 61, 0.40)',
    '0px 18px 18px 0px rgba(62, 61, 61, 0.40)',
    '0px 20px 20px 0px rgba(62, 61, 61, 0.40)',
    '0px 22px 22px 0px rgba(62, 61, 61, 0.40)',
    '0px 24px 24px 0px rgba(62, 61, 61, 0.40)',
    '0px 26px 26px 0px rgba(62, 61, 61, 0.40)',
    '0px 28px 28px 0px rgba(62, 61, 61, 0.40)',
    '0px 30px 30px 0px rgba(62, 61, 61, 0.40)'
  ],
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }
      }
    },
    ...override(['MuiInputBase', 'MuiOutlinedInput', 'MuiFilledInput', 'MuiInput'], {
      styleOverrides: {
        root: {
          borderRadius: INPUT_BORDER_RADIUS
        }
      }
    })
  }
}
