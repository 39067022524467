import React from 'react'
import { observer } from 'mobx-react'
import styled from '../../../utils/styled'
import { Typography } from '@mui/material'
import MiniReservation from '../../mini_reservation'

const Label = styled(Typography).attrs(() => ({
  variant: 'body1'
}))`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-weight: 700;
`

const Warning = styled(Typography).attrs(() => ({
  variant: 'body2'
}))`
  font-weight: 600;
  margin-bottom: -20px;
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const Reservation = styled(MiniReservation)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme, $last }) => !$last && `border-bottom: 1px solid ${theme.palette.divider}`};
`

const ConfirmAlreadyReserved = ({ instances }) => {
  return (
    <>
      <Label>
        You have selected {instances.length} instance{instances.length > 1 ? 's that are' : ' that is'} already
        reserved:
      </Label>
      {instances.map((instance, index) => (
        <Reservation
          reservation={instance.activeReservation}
          instance={instance}
          showInstance
          key={instance.name}
          $last={index === instance.length - 1}
        />
      ))}
      <Warning>Please make sure to ask for permission before taking over someone else's reservation.</Warning>
    </>
  )
}

export default observer(ConfirmAlreadyReserved)
