import React from 'react'
import { Typography, Avatar } from '@mui/material'
import { CRIMSON_1, GREEN_PINE_OIL, TEAL_1 } from '../constants/brand'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import styled from '../utils/styled'
import { useStore } from '../store'
import { CalendarClock, HardDrive, Tag } from 'lucide-mui'
import isSameDay from 'date-fns/isSameDay'

const formatReservationRange = (from, until) => {
  const fromISO = parseISO(from)
  const untilISO = until ? parseISO(until) : null
  const sameDay = untilISO ? isSameDay(untilISO, fromISO) : false

  if (!untilISO) {
    return `${format(fromISO, 'MMM do, p')} (no limit)`
  }

  if (sameDay) {
    return `${format(fromISO, 'MMM do')}, ${format(fromISO, 'p')} - ${format(untilISO, 'p')}`
  } else {
    return `${format(fromISO, 'MMM do, p')} - ${format(untilISO, 'MMM do, p')}`
  }
}

const ReservationContent = styled('div')`
  display: flex;
  align-items: flex-start;
  ${({ $active }) => $active && 'font-weight: 700;'}
`

const ReservationLineContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: ${({ theme, $color }) => $color || theme.palette.text.secondary};
  margin-top: ${({ theme, $small }) => theme.spacing($small ? 0.25 : 0.5)};
  font-size: ${({ theme, $small }) => ($small ? '0.75rem' : '0.875rem')};
`

const ReservationLine = ({ icon: Icon, children, small, bold, ...props }) => (
  <ReservationLineContainer {...props} $small={small}>
    <Icon sx={{ fontSize: small ? 12 : 18, mr: small ? 0.5 : 0.75, mb: small ? 0.55 : 0.45 }} color='inherit' />
    <Typography variant='subtitle2' color='inherit' sx={{ fontSize: 'inherit', fontWeight: bold ? '700' : 'inherit' }}>
      {children}
    </Typography>
  </ReservationLineContainer>
)

const MiniReservation = ({ reservation, instance, showInstance, ...props }) => {
  const { users } = useStore()
  const user = users.get(reservation.username)

  return (
    <ReservationContent $active={reservation.active} {...props}>
      <Avatar src={user?.avatarUrl} sx={{ mr: 1, width: 20, height: 20 }} />
      <div>
        <Typography variant='body2' sx={{ fontWeight: 'inherit' }}>
          {user?.name || reservation.username}
        </Typography>

        {showInstance && (
          <ReservationLine icon={HardDrive} $color={CRIMSON_1} small bold>
            {instance.name}
          </ReservationLine>
        )}

        <ReservationLine icon={CalendarClock} $color={GREEN_PINE_OIL} small>
          {formatReservationRange(reservation.activeFrom, reservation.activeUntil)}
        </ReservationLine>

        {reservation.label && (
          <ReservationLine icon={Tag} $color={TEAL_1} small>
            {reservation.label}
          </ReservationLine>
        )}
      </div>
    </ReservationContent>
  )
}

export default MiniReservation
