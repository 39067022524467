import React from 'react'
import styled from '../../../utils/styled'
import { Link, useLocation } from 'react-router-dom'

const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ $isActive, theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.spacing(4)};

  ${({ $isActive }) => $isActive && 'background: #fff;'};
`

const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`

const ButtonText = styled('span')`
  border: 0;
  font-size: 16px;
  margin-left: ${({ theme }) => theme.spacing(1)};
`

const Children = styled('div')`
  display: ${({ $isActive }) => ($isActive ? 'block' : 'none')};
`

const Button = ({ to, active, icon: Icon, label, children, ...props }) => {
  const { pathname } = useLocation()
  const isActive = active
    ? typeof active === 'string'
        ? pathname === active
        : active.test(pathname)
    : pathname.startsWith(to)

  return (
    <>
      <ButtonContainer to={to} {...props} $isActive={isActive}>
        <IconContainer>
          <Icon />
        </IconContainer>
        <ButtonText>{label}</ButtonText>
      </ButtonContainer>
      <Children $isActive={isActive}>{children}</Children>
    </>
  )
}

export default Button
