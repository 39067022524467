import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate } from 'react-router'
import { useLazyQuery, useMutation } from '@apollo/client'
import { styled, useTheme } from '@mui/material/styles'
import { Grid, Container, CircularProgress, useMediaQuery } from '@mui/material'
import RocketLaunch from 'mdi-material-ui/RocketLaunch'
import { useSnackbar } from 'notistack'
import { useSetState } from 'rooks'
import { DEPLOYMENT_CREATE } from '../../../api/deployments'
import { useConfig } from '../../../config'
import { GREEN_GROWN_UP_GREEN } from '../../../constants/brand'
import { Deployment } from '../../../models'
import { useStore } from '../../../store'
import JiraIssue from '../../jira_issue'
import Title from '../../title'
import SimplifiedCard from '../../simplified_card'
import JiraForm from '../../jira_form'
import Instances from './instances'
import Apps from './apps'
import Warnings from './warnings'
import Checkboxes from './checkboxes'
import { useCreateJiraIssues } from '../../../utils/jira'
import { FETCH_JIRA_VERSIONS } from '../../../api/jira'
import { usePrevious } from '../../../utils/hooks'
import ConfirmButton from '../../confirm_button'
import { useInstances } from '../../../api/instances'
import ConfirmAlreadyReserved from './confirm_already_reserved'

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(3)};
`

const Create = () => {
  const deployment = useState(() => Deployment.create())[0]
  const location = useLocation()
  const navigate = useNavigate()
  const { jiraIssues, removeJiraIssue } = useCreateJiraIssues()
  const previousJiraIssues = usePrevious(jiraIssues)
  const config = useConfig()
  const {
    session: { currentUser }
  } = useStore()

  const { instances, loading: instancesLoading } = useInstances()
  const [createDeployment, { loading, error }] = useMutation(DEPLOYMENT_CREATE)
  const [refetchVersions, { loading: issueVersionsLoading }] = useLazyQuery(FETCH_JIRA_VERSIONS, {
    onCompleted: (data) => {
      const versions = data?.jiraIssueVersions?.versions
      const warnings = data?.jiraIssueVersions?.warnings

      if (deployment) {
        if (versions) {
          deployment.setFromJiraIssueVersions(versions, config.apps)
        }
        if (warnings) {
          warnings.forEach((warning) => displayWarning({ message: warning, variant: 'warning' }))
        }
      }
    }
  })

  useEffect(() => {
    if (jiraIssues.length > 0) {
      // Not so nice hack but I could not think of a better way to do it, we don't want to reset form state if someone
      // removes a Jira ticket, only when they add one. But issues are stored in the URL so we cannot just run
      // callback in JiraForm because the component did not re-render by the time we do it
      const addedJiraIssues = jiraIssues.length > (previousJiraIssues?.length || 0)

      if (addedJiraIssues) {
        refetchVersions({ variables: { keys: jiraIssues } })
      }
    }
  }, [jiraIssues])

  useEffect(() => {
    if (deployment) {
      const instances = location?.state?.instances?.filter((instance) => !!instance)

      if (instances) {
        deployment.setInstances(instances)
      }
    }
  }, [location?.state?.instances])

  const theme = useTheme()
  const condensedLayout = useMediaQuery(theme.breakpoints.down('md'))

  const [displayedWarningsSet, controlDisplayedWarnings] = useSetState([])
  const displayedWarnings = Array.from(displayedWarningsSet).reverse()

  const displayWarning = (warning) => controlDisplayedWarnings.add(warning)
  const closeWarning = (warning) => controlDisplayedWarnings.delete(warning)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const alreadyReservedInstances = instances
    .filter((instance) => !!instance.activeReservation && instance.activeReservation.username !== currentUser.email)
    .filter((instance) => deployment.instances.some((i) => i.name === instance.name))

  const onSubmit = () => {
    if (deployment.apps.length === 0) {
      enqueueSnackbar('Cannot trigger an empty deployment', { variant: 'error' })
      return false
    }

    if (deployment.instances.length === 0) {
      enqueueSnackbar('Cannot trigger a deployment without testing instances', { variant: 'error' })
      return false
    }

    if (alreadyReservedInstances.length === 0) {
      return true
    }
  }

  const startDeployment = async () => {
    closeSnackbar()

    try {
      if (deployment.apps.length === 0) {
        throw new Error('Cannot trigger an empty deployment')
      }

      if (deployment.instances.length === 0) {
        throw new Error('Cannot trigger a deployment without testing instances')
      }

      const result = await createDeployment({
        variables: {
          username: currentUser.email,
          jiraIssues,
          ...deployment.toGraphQLInput()
        }
      })

      const id = result?.data?.deploymentCreate?.deployment?.id
      if (id) {
        navigate(`/build/${id}`)
      }
    } catch (e) {
      // Ignore GraphQL errors as they're handled displayed elsewhere
      if (!Object.getOwnPropertyDescriptor(e, 'graphQLErrors')) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
      console.error(e)
    }
  }

  const onRemoveJiraIssue = (issue) => {
    removeJiraIssue(issue)

    const newJiraIssues = jiraIssues.filter((i) => i !== issue)
    navigate('/create', { state: { ...location.state, jiraIssues: newJiraIssues } })
  }

  return (
    <Container>
      <Title title='New deployment' colors={[GREEN_GROWN_UP_GREEN, GREEN_GROWN_UP_GREEN]} />
      <Grid container spacing={3}>
        <Grid item md={7} sm={12} xs={12}>
          {condensedLayout && (
            <>
              <JiraForm sx={{ marginBottom: 3 }} />
              {jiraIssues.map((issue) => (
                <JiraIssue key={issue} issue={issue} onRemove={() => onRemoveJiraIssue(issue)} />
              ))}
            </>
          )}
          <Apps
            apps={config.apps}
            deployment={deployment}
            sx={condensedLayout ? { mb: 0 } : {}}
            condensed={condensedLayout}
            disabled={issueVersionsLoading}
          />
          {!condensedLayout && <Warnings warnings={displayedWarnings} closeWarning={closeWarning} />}
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          {!condensedLayout && (
            <>
              <JiraForm sx={{ marginBottom: 3 }} />
              {jiraIssues.map((issue) => (
                <JiraIssue key={issue} issue={issue} onRemove={() => onRemoveJiraIssue(issue)} />
              ))}
            </>
          )}
          <SimplifiedCard title='Testing instances' sx={{ marginBottom: 3 }}>
            <Instances
              deployment={deployment}
              displayWarning={displayWarning}
              instances={instances}
              loading={instancesLoading}
            />
          </SimplifiedCard>
          <SimplifiedCard title='Additional options'>
            <Checkboxes app={deployment.backendApp} deployment={deployment} />
          </SimplifiedCard>

          {condensedLayout && <Warnings warnings={displayedWarnings} closeWarning={closeWarning} sx={{ mt: 3 }} />}

          <ButtonContainer>
            <ConfirmButton
              variant='contained'
              color={error ? 'secondary' : 'primary'}
              disabled={!!loading || !deployment.instances.length === 0}
              startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <RocketLaunch />}
              onBeforeConfirm={onSubmit}
              onClick={startDeployment}
              messageContent={
                alreadyReservedInstances.length > 0
                  ? (
                    <ConfirmAlreadyReserved instances={alreadyReservedInstances} />
                    )
                  : null
              }
            >
              Start deployment
            </ConfirmButton>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

export default observer(Create)
