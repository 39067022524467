import React from 'react'
import { CRIMSON_1, GRAY_2, GREEN_GROWN_UP_GREEN, TEAL_1 } from '../../../../constants/brand'
import { Dot, SubmenuItem, SubmenuIcon, SubmenuLink, Submenu } from './_components'
import { useStore } from '../../../../store'
import {
  INSTANCE_RUNNING,
  INSTANCE_STARTING,
  INSTANCE_STOPPED,
  INSTANCE_STOPPING
} from '../../../../constants/statuses'
import { useInstances } from '../../../../api/instances'
import { useIsActiveUrl } from '../../../../utils/use_is_active_url'
import { Skeleton } from '@mui/material'

const dotColor = (status) => {
  if (status === INSTANCE_RUNNING) return GREEN_GROWN_UP_GREEN
  if (status === INSTANCE_STARTING || status === INSTANCE_STOPPING) return TEAL_1
  if (status === INSTANCE_STOPPED) return CRIMSON_1
  return GRAY_2
}

const SidebarSubmenuInstances = () => {
  const store = useStore()
  const isActiveURL = useIsActiveUrl()
  const username = store.session.currentUser.email
  const { instances, loading } = useInstances({
    filter: ({ activeReservation }) => activeReservation?.username === username
  })

  return (
    <Submenu $empty={instances.length === 0}>
      {loading &&
        new Array(2).fill(0).map((_, i) => (
          <SubmenuItem key={i}>
            <SubmenuIcon>
              <Dot color={GREEN_GROWN_UP_GREEN} size={6} />
            </SubmenuIcon>
            <Skeleton variant='text' width='80px' sx={{ ml: '5px', mr: '38px' }} />
          </SubmenuItem>
        ))}
      {instances.map((instance) => (
        <SubmenuItem key={instance.name} $isActive={isActiveURL(`/instances/${instance.name}`)}>
          <SubmenuIcon>
            <Dot color={dotColor(instance.status)} size={6} />
          </SubmenuIcon>
          <SubmenuLink to={`/instances/${instance.name}`}>{instance.name}</SubmenuLink>
        </SubmenuItem>
      ))}
    </Submenu>
  )
}

export default SidebarSubmenuInstances
