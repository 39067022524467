import React from 'react'
import { observer } from 'mobx-react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { styled } from '@mui/material/styles'
import ReservationForm from '../../reservation_form'
import { useStore } from '../../../store'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
`

const Checkboxes = ({ app, deployment }) => {
  const {
    session: {
      currentUser: { email: username }
    }
  } = useStore()
  const { reservation, setReservation, clearReservation, instances } = deployment

  return (
    <Container>
      <FormControlLabel
        control={
          <Checkbox
            name='runMigrations'
            color='primary'
            disabled={!app}
            checked={!!app?.runMigrations}
            onChange={() => app?.setRunMigrations(!app?.runMigrations)}
          />
        }
        label='Run database migrations'
      />
      <FormControlLabel
        control={
          <Checkbox
            name='refreshTranslations'
            color='primary'
            disabled={!app}
            checked={!!app?.refreshTranslations}
            onChange={() => app?.setRefreshTranslations(!app?.refreshTranslations)}
          />
        }
        label='Refresh translations'
      />
      <FormControlLabel
        control={
          <Checkbox
            name='reserveInstances'
            color='primary'
            disabled={instances.length === 0}
            checked={!!reservation}
            onChange={() => {
              if (!reservation) {
                setReservation({ username })
              } else {
                clearReservation(null)
              }
            }}
          />
        }
        label='Reserve the instances'
      />
      {!!reservation && <ReservationForm reservation={reservation} single={instances.length === 1} sx={{ mt: 2 }} />}
    </Container>
  )
}

export default observer(Checkboxes)
