import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ReservationForm from '../../../reservation_form'
import { ReservationInput } from '../../../../models'
import { useStore } from '../../../../store'

const ReserveDialogForm = ({ name, onClose, onReserve, loading }) => {
  const {
    session: {
      currentUser: { email: username }
    }
  } = useStore()
  const reservation = useState(ReservationInput.create({ username }))[0]

  const onReserveClick = async () => {
    await onReserve(reservation)
    onClose()
  }

  return (
    <>
      <DialogTitle id='alert-dialog-title'>Reserve {name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ReservationForm reservation={reservation} single sx={{ width: 450, mt: 2, mb: 2 }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onReserveClick} autoFocus>
          Reserve instance
        </LoadingButton>
      </DialogActions>
    </>
  )
}

const ReserveDialog = ({ name, activeReservation, loading, onClose, onReserve, ...props }) => {
  return (
    <Dialog {...props}>
      <ReserveDialogForm name={name} onClose={onClose} onReserve={onReserve} loading={loading} />
    </Dialog>
  )
}

export default ReserveDialog
