/* PRIMARIES */
export const GREEN_GROWN_UP_GREEN = '#306c60'
export const GREEN_SUMMER_GRASS = '#5c8e85'

/* SECONDARIES */
export const GREEN_PINE_OIL = '#2a5450'
export const GREEN_SAGE_TEA = '#93b4ae'
export const GREEN_IRISH_BREEZE = '#bed2ce'
export const GREEN_FROSTY_GREEN = '#dee9e7'
export const GREEN_WINTER_MIST = '#eff4f3'

export const CTA_PURPLE = '#878bb0'
export const CTA_GREEN = '#52b68d'
export const CTA_SALMON = '#d25f66'
export const CTA_DARK = '#111414'

export const GRAY_0 = '#1e2121'
export const GRAY_1 = '#515759'
export const GRAY_2 = '#74797a'
export const GRAY_3 = '#a7abad'
export const GRAY_4 = '#f1f2f2'
export const GRAY_5 = '#f7f8f8'

export const TEXT_DARK = GRAY_0
export const TEXT_MEDIUM = '#434C4D'
export const TEXT_LIGHT = '#858C92'

export const GRAY_LIGHT = '#cccccc'
export const GRAY_BORDER = '#e8e8e8'

export const WHITE = '#ffffff'
export const BLACK = '#000000'
export const TRANSPARENT = 'transparent'

export const TEAL_1 = '#0e7390'
export const TEAL_2 = '#569db1'
export const TEAL_3 = '#86b9c7'
export const TEAL_4 = '#b6d5dd'
export const TEAL_5 = '#e2eef2'
export const TEAL_6 = '#f1f9fc'

export const VIOLET_1 = '#5a5f89'
export const VIOLET_2 = '#878bb0'
export const VIOLET_3 = '#bec3d7'
export const VIOLET_4 = '#d7d9e7'
export const VIOLET_5 = '#edeef3'

export const GOLD_1 = '#c09551'
export const GOLD_2 = '#d3b585'
export const GOLD_3 = '#dfcaa8'
export const GOLD_4 = '#ecdfca'
export const GOLD_5 = '#f7f2ea'

export const CRIMSON_1 = '#a9454b'
export const CRIMSON_2 = '#ba6a6f'
export const CRIMSON_3 = '#d4a2a5'
export const CRIMSON_4 = '#e5c7c9'
export const CRIMSON_5 = '#f6eced'

export const WARNING_LIGHT = '#ffbf47'
export const WARNING = '#e67e22'
export const WARNING_DARK = '#b56736'
