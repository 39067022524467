import { bindDialog, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'
import { useMutation } from '@apollo/client'
import { RESERVE_INSTANCE } from '../../../api/instances'
import { useStore } from '../../../store'

// FIXME: this code is a mess but I'm running out of time to make it nice
//        this should all be refactored and the reservation dialog should only be rendered once
//        and the triggering code should be in UI store
const useReserve = ({ name, activeReservation, onClick = () => null }) => {
  const popupState = usePopupState({ variant: 'dialog' })
  const [reserve, { loading }] = useMutation(RESERVE_INSTANCE)
  const {
    session: {
      currentUser: { email }
    }
  } = useStore()

  const onReserve = (reservation) =>
    reserve({
      variables: {
        instance: name,
        reservation: reservation?.toGraphQLInput()
      }
    })
  const onRelease = () => reserve({ variables: { instance: name, reservation: { username: null } } })

  // prettier-ignore
  const withOnClick = (fn) => (...args) => { onClick(); return fn(...args) }

  // these can't trigger onClick because for MenuItem inside a dropdown that would unmount the Dialog component
  // TODO: refactor reservation dialog to sit outside specific dropdown menus of instances
  const triggerProps = bindTrigger(popupState)
  const releaseTriggerProps = { onClick: withOnClick(onRelease), onTouchStart: withOnClick(onRelease) }

  return {
    triggerProps: activeReservation?.username === email ? releaseTriggerProps : triggerProps,
    dialogProps: {
      name,
      activeReservation,
      loading,
      onClose: popupState.close,
      onReserve,
      ...bindDialog(popupState)
    },
    loading,
    onRelease
  }
}

export default useReserve
