import React from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableRow as UnstyledTableRow,
  TableCell as UnstyledTableCell,
  Skeleton
} from '@mui/material'
import SimplifiedCard from '../../../simplified_card'
import styled from '../../../../utils/styled'
import MiniReservation from '../../../mini_reservation'

const TableRow = styled(UnstyledTableRow)`
  ${(props) =>
    !props.withBottomBorder &&
    `
    &:last-child td, &:last-child th {
      border: 0
    }
  `}
`

const TableCell = styled(UnstyledTableCell)`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${(props) =>
    props.$collapse &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 0;
    white-space: pre;
  `}
`

const ReservationsSkeleton = () => (
  <>
    <Skeleton variant='rectangular' sx={{ mt: 1.5, mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2, opacity: 0.75 }} animation='wave' />
  </>
)

const RecentReservationsCard = ({ partiallyLoaded, reservations }) => {
  return (
    <SimplifiedCard title='Recent reservations' sx={{ mb: 2 }} noExtraBottomPadding>
      {partiallyLoaded && <ReservationsSkeleton />}
      {reservations.length > 0 && (
        <Table size='small' sx={{ mb: 1 }}>
          <TableBody>
            {reservations.map((reservation) => (
              <TableRow key={reservation.id} $active={reservation.active}>
                <TableCell>
                  <MiniReservation reservation={reservation} key={reservation.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!partiallyLoaded && reservations.length === 0 && (
        <Typography variant='body1'>This testing instance has never been reserved</Typography>
      )}
    </SimplifiedCard>
  )
}

export default RecentReservationsCard
