import { gql, useQuery } from '@apollo/client'
import { useEffect } from 'react'

export const FETCH_INSTANCES = gql`
  query instances($reservedBy: String) {
    instances(reservedBy: $reservedBy) {
      name
      country
      number
      status
      domain
      latestSemaphoreBuilds {
        app
        appType
        branch
        updatedAt
      }
      latestJenkinsBuilds {
        app
        appType
        revision
        updatedAt
      }
      activeReservation {
        username
        activeUntil
        activeFrom
        label
      }
    }
  }
`

export const FETCH_INSTANCE = gql`
  query instance($name: String!) {
    instance(name: $name) {
      name
      country
      number
      status
      domain
      metadata {
        rubyVersion
        railsVersion
        databaseAge
        uptime
        loadAvgs
      }
      latestSemaphoreBuilds {
        app
        appType
        appUrl
        branch
        revision
        updatedAt
      }
      latestJenkinsBuilds {
        app
        appType
        branch
        revision
        updatedAt
      }
      activeReservation {
        username
        activeUntil
        activeFrom
        label
      }
      reservations(last: 8) {
        edges {
          node {
            id
            username
            active
            activeFrom
            activeUntil
            label
          }
        }
      }
    }
  }
`

export const FETCH_INSTANCE_STATUS = gql`
  query instance($name: String!) {
    instance(name: $name) {
      status
    }
  }
`

export const START_INSTANCE = gql`
  mutation startInstance($name: String!) {
    instanceStart(name: $name) {
      success
    }
  }
`

export const STOP_INSTANCE = gql`
  mutation stopInstance($name: String!) {
    instanceStop(name: $name) {
      success
    }
  }
`

export const RESERVE_INSTANCE = gql`
  mutation reserveInstance($instance: String, $reservation: ReservationInput!) {
    instanceReserve(instance: $instance, reservation: $reservation) {
      success
    }
  }
`

export const INSTANCES_SUBSCRIPTION = gql`
  subscription instancesUpdated {
    instancesUpdated {
      instances {
        name
        country
        number
        status
        domain
        latestSemaphoreBuilds {
          app
          appType
          branch
          updatedAt
        }
        latestJenkinsBuilds {
          app
          appType
          revision
          updatedAt
        }
        activeReservation {
          username
          activeUntil
          activeFrom
          label
        }
      }
    }
  }
`

export const INSTANCE_SUBSCRIPTION = gql`
  subscription instanceUpdated($name: String!) {
    instanceUpdated(name: $name) {
      instance {
        name
        country
        number
        status
        domain
        metadata {
          rubyVersion
          railsVersion
          databaseAge
          uptime
          loadAvgs
        }
        latestSemaphoreBuilds {
          app
          appType
          appUrl
          branch
          revision
          updatedAt
        }
        latestJenkinsBuilds {
          app
          appType
          branch
          revision
          updatedAt
        }
        activeReservation {
          username
          activeUntil
          activeFrom
          label
        }
        reservations(last: 8) {
          edges {
            node {
              id
              username
              active
              activeFrom
              activeUntil
              label
            }
          }
        }
      }
    }
  }
`

export const useInstances = ({ filter, limit } = {}) => {
  const { data, loading, error, subscribeToMore } = useQuery(FETCH_INSTANCES)

  useEffect(
    () =>
      subscribeToMore({
        document: INSTANCES_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.instancesUpdated) return prev

          const instances = subscriptionData.data.instancesUpdated.instances

          return { instances }
        }
      }),
    [filter, limit]
  )

  let instances = data?.instances || []

  if (filter) {
    instances = instances.filter(filter)
  }
  if (limit) {
    instances = instances.slice(0, limit)
  }

  return { instances, loading, error }
}

export const useInstance = ({ name } = {}) => {
  const { data, loading, error, subscribeToMore } = useQuery(FETCH_INSTANCE, {
    variables: { name }
  })

  useEffect(
    () =>
      subscribeToMore({
        document: INSTANCE_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.instanceUpdated) return prev

          return { instance: subscriptionData.data.instanceUpdated.instance }
        },
        variables: { name }
      }),
    [name]
  )

  return { instance: data?.instance, loading, error }
}
